import MapAreaFilter from '@/components/filters/map-area-filter/MapAreaFilter';
import { MapArea, Position } from '@/models/MapArea';
import { Project } from '@/models/Project';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import CreateMapAreaDialog from '../../CreateMapAreaDialog.vue';
import { PetatLegendaTypes } from '../../PeTAT';

@Component<Areas>({
  components: {
    CreateMapAreaDialog,
  },
})
export default class Areas extends Vue {
  // #region @Props
  @Prop()
  protected project!: Project | null;

  @Prop()
  protected selectedArea!: MapArea | null;

  @Prop()
  protected newAreaPoints!: Position[];

  @Prop()
  protected activeMapAreaPoint!: Position | null;

  @Prop()
  protected activeMapAreaIndex!: number;

  @Prop()
  protected latitude!: number;

  @Prop()
  protected longitude!: number;

  @Prop()
  protected legendaType!: PetatLegendaTypes;

  @Prop()
  protected isActiveLockCoordinates!: boolean;

  @Prop()
  protected isActiveSelectionTool!: boolean;

  @Prop()
  protected isActiveCoordinatesTool!: boolean;
  // #endregion

  // #region @Refs
  @Ref()
  readonly mapAreaFilter!: MapAreaFilter;
  // #endregion

  // #region Class properties
  protected isCreatingMapArea = false;
  // #endregion

  // #region Lifecycle Hooks / Init
  // #endregion

  // #region Class methods
  protected handleSelectedArea(area: MapArea) {
    if (! area || ! area.contour) {
      this.$emit('update:selectedArea', null);
      this.$emit('update:activeMapAreaPoint', null);
      this.$emit('update:newAreaPoints', []);
      this.newAreaPoints = [];
      return;
    }

    this.$emit('update:selectedArea', area);
    this.$emit('update:newAreaPoints', area.contour);
  }

  protected handleMapAreaCreated() {
    this.mapAreaFilter.getMapAreas();
  }

  protected setActiveMapAreaMaker(position: Position) {
    this.$emit('update:activeMapAreaPoint', position);
  }

  protected setActiveMapAreaIndex(index: number) {
    this.$emit('update:activeMapAreaIndex', index);
  }

  protected deleteArea(index: number) {
    this.newAreaPoints.splice(index, 1);
  }
  // #endregion

  // #region Async methods
  protected async updateArea() {
    if (! this.selectedArea) {
      return;
    }

    this.selectedArea
      .updateContour({
        contour: this.newAreaPoints,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  // #endregion
}
