import { Component, Vue, Prop } from 'vue-property-decorator';
import { getMakerColor } from '@/support/GoogleMapHelpers';
import { getStatusLabel } from '@/support/ReportStatus';
import { Marker, PetatLegendaTypes } from '../PeTAT/PeTAT';

@Component<PeTATLegenda>({
  components: {
    //
  },
})
export default class PeTATLegenda extends Vue {
  @Prop({ required: true })
  protected legendaType!: PetatLegendaTypes;

  protected mounted() {
  }

  protected getMarkerColor(marker: Marker) {
    return getMakerColor(marker, this.legendaType);
  }

  protected get legendaItems(): LegandaItem[] {
    if (this.legendaType === PetatLegendaTypes.PROJECT) {
      return this.legendaItemsProject;
    }

    if (this.legendaType === PetatLegendaTypes.PLANNING) {
      return this.legendaItemsPlanning;
    }

    if (this.legendaType === PetatLegendaTypes.DAMAGE_COUNT) {
      return this.legendaItemsDamageCount;
    }

    if (this.legendaType === PetatLegendaTypes.CAUSAAL_COUNT) {
      return this.legendaItemsCausalCount;
    }

    if (this.legendaType === PetatLegendaTypes.MAX_PGV) {
      return this.legendaItemsMaxPgv;
    }

    return this.legendaItemsPayout;
  }

  protected get legendaItemsProject(): LegandaItem[] {
    return [
      {
        label: 'Niet in project',
        legenda: {
          in_project: false,
        },
      },
      {
        label: 'In project',
        legenda: {
          in_project: true,
        },
      },
    ];
  }

  protected get legendaItemsPayout(): LegandaItem[] {
    return [
      {
        label: 'Nog te lopen',
        legenda: {
          payout_total: - 1,
        },
      },
      {
        label: '€0',
        legenda: {
          payout_total: 0,
        },
      },
      {
        label: '€0 tot €5.000',
        legenda: {
          payout_total: 1,
        },
      },
      {
        label: '€5.000 tot €10.000',
        legenda: {
          payout_total: 5001,
        },
      },
      {
        label: '€10.000 tot €20.000',
        legenda: {
          payout_total: 10001,
        },
      },
      {
        label: '€20.000 tot €50.000',
        legenda: {
          payout_total: 20001,
        },
      },
      {
        label: '€50.000 tot €100.000',
        legenda: {
          payout_total: 50001,
        },
      },
      {
        label: '€100.000 +',
        legenda: {
          payout_total: 100001,
        },
      },
    ];
  }

  protected get legendaItemsDamageCount(): LegandaItem[] {
    return [
      {
        label: '0',
        legenda: {
          damage_count: 0,
        },
      },
      {
        label: '1 - 10',
        legenda: {
          damage_count: 1,
        },
      },
      {
        label: '11 - 20',
        legenda: {
          damage_count: 11,
        },
      },
      {
        label: '21 - 30',
        legenda: {
          damage_count: 21,
        },
      },
      {
        label: '31 - 40',
        legenda: {
          damage_count: 31,
        },
      },
      {
        label: '41 - 50',
        legenda: {
          damage_count: 41,
        },
      },
      {
        label: '51 - 60',
        legenda: {
          damage_count: 51,
        },
      },
      {
        label: '61 - 70',
        legenda: {
          damage_count: 61,
        },
      },
      {
        label: '70+',
        legenda: {
          damage_count: 71,
        },
      },
    ];
  }

  protected get legendaItemsCausalCount(): LegandaItem[] {
    return [
      {
        label: '0',
        legenda: {
          causaal_count: 0,
        },
      },
      {
        label: '1 - 10',
        legenda: {
          causaal_count: 1,
        },
      },
      {
        label: '11 - 20',
        legenda: {
          causaal_count: 11,
        },
      },
      {
        label: '21 - 30',
        legenda: {
          causaal_count: 21,
        },
      },
      {
        label: '31 - 40',
        legenda: {
          causaal_count: 31,
        },
      },
      {
        label: '41 - 50',
        legenda: {
          causaal_count: 41,
        },
      },
      {
        label: '51 - 60',
        legenda: {
          causaal_count: 51,
        },
      },
      {
        label: '61 - 70',
        legenda: {
          causaal_count: 61,
        },
      },
      {
        label: '70+',
        legenda: {
          causaal_count: 71,
        },
      },
    ];
  }

  protected get legendaItemsMaxPgv(): LegandaItem[] {
    return [
      {
        label: 'Onbekend',
        legenda: {
          max_pgv: - 1,
        },
      },
      {
        label: '0 mm/s - 2 mm/s',
        legenda: {
          max_pgv: 1,
        },
      },
      {
        label: '2 mm/s - 3 mm/s',
        legenda: {
          max_pgv: 2.1,
        },
      },
      {
        label: '3 mm/s - 4 mm/s',
        legenda: {
          max_pgv: 3.1,
        },
      },
      {
        label: '4 mm/s - 8,5 mm/s',
        legenda: {
          max_pgv: 4.1,
        },
      },
      {
        label: '8,5 mm/s - 20 mm/s',
        legenda: {
          max_pgv: 9,
        },
      },
      {
        label: '20 mm/s - 40 mm/s',
        legenda: {
          max_pgv: 21,
        },
      },
      {
        label: '40 mm/s - 80 mm/s',
        legenda: {
          max_pgv: 41,
        },
      },
      {
        label: '80 mm/s +',
        legenda: {
          max_pgv: 81,
        },
      },
    ];
  }

  protected get legendaItemsPlanning(): LegandaItem[] {
    // if(planning_status === 'unplanned') {
    //   color = 'purple';
    // } else if(planning_status === 'no_answer') {
    //   color = 'yellow';
    // } else if(planning_status === 'planned') {
    //   color = 'green';
    // } else if(planning_status === 'not_needed_anymore') {
    //   color = 'gray';
    // } else if(planning_status === 'vervolgopname') {
    //   color = 'orange';
    // } else if(planning_status === 'nader_onderzoek') {
    //   color = 'black';
    // } else if(planning_status === 'aanvullend_onderzoek') {
    //   color = 'brown';
    // } else if(planning_status === 'heropname') {
    //   color = 'red';
    // } else if(planning_status === 'no_action_required') {
    //   color = 'white';
    // }

    return [
      {
        label: getStatusLabel('unplanned'),
        legenda: {
          planning_status: 'unplanned',
        },
      },
      {
        label: getStatusLabel('no_answer'),
        legenda: {
          planning_status: 'no_answer',
        },
      },
      {
        label: getStatusLabel('planned'),
        legenda: {
          planning_status: 'planned',
        },
      },
      {
        label: getStatusLabel('not_needed_anymore'),
        legenda: {
          planning_status: 'not_needed_anymore',
        },
      },
      {
        label: getStatusLabel('vervolgopname'),
        legenda: {
          planning_status: 'vervolgopname',
        },
      },
      {
        label: getStatusLabel('nader_onderzoek'),
        legenda: {
          planning_status: 'nader_onderzoek',
        },
      },
      {
        label: getStatusLabel('aanvullend_onderzoek'),
        legenda: {
          planning_status: 'aanvullend_onderzoek',
        },
      },
      {
        label: getStatusLabel('heropname'),
        legenda: {
          planning_status: 'heropname',
        },
      },
      {
        label: getStatusLabel('no_action_required'),
        legenda: {
          planning_status: 'no_action_required',
        },
      },
    ];
  }
}

export interface LegandaItem {
  label: string;
  legenda: any;
}
