import { Earthquake, Marker, PetatLegendaTypes } from '@/components/PeTAT/PeTAT';

export const getActiveMarker = () => 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png';

export const getMakerColor = (marker: Marker, type: PetatLegendaTypes) => {
  if (type === PetatLegendaTypes.PROJECT) {
    return getMakerColorProject(marker);
  }

  if (type === PetatLegendaTypes.DAMAGE_COUNT) {
    return getMakerColorDamageCount(marker);
  }

  if (type === PetatLegendaTypes.MAX_PGV) {
    return getMakerColorMaxPgv(marker);
  }

  if (type === PetatLegendaTypes.CAUSAAL_COUNT) {
    return getMakerColorCausaalCount(marker);
  }

  if (type === PetatLegendaTypes.PLANNING) {
    if (! marker.legenda.planning_status) {
      return require('@/assets/images/icons/pins/white-pin.svg');
    }

    return getMakerColorPlanning[marker.legenda.planning_status];
  }

  return getMakerColorPayout(marker);
};

export const getMakerColorProject = (marker: Marker) => {
  if (marker.legenda.in_project) {
    return require('@/assets/images/icons/pins/orange-pin.svg');
  }

  return require('@/assets/images/icons/pins/blue-pin.svg');
};

export const getMakerColorPayout = (marker: Marker) => {
  const payout_total = marker.legenda.payout_total;

  if (payout_total > 100000) {
    return require('@/assets/images/icons/pins/purple-pin.svg');
  }
  if (payout_total > 50000) {
    return require('@/assets/images/icons/pins/red-pin.svg');
  }
  if (payout_total > 20000) {
    return require('@/assets/images/icons/pins/orange-pin.svg');
  }
  if (payout_total > 10000) {
    return require('@/assets/images/icons/pins/yellow-pin.svg');
  }
  if (payout_total > 5000) {
    return require('@/assets/images/icons/pins/green-pin.svg');
  }
  if (payout_total > 0) {
    return require('@/assets/images/icons/pins/grey-pin.svg');
  }
  if (payout_total === 0) {
    return require('@/assets/images/icons/pins/white-pin.svg');
  }

  if (marker.legenda.payout_total === null || payout_total < 0) {
    return require('@/assets/images/icons/pins/blue-pin.svg');
  }

  return require('@/assets/images/icons/pins/white-pin.svg');
};

export const getMakerColorDamageCount = (marker: Marker) => {
  const damage_count = marker.legenda.damage_count;

  if (damage_count > 70) {
    return require('@/assets/images/icons/pins/purple-pin.svg');
  }
  if (damage_count > 60) {
    return require('@/assets/images/icons/pins/red-pin.svg');
  }
  if (damage_count > 50) {
    return require('@/assets/images/icons/pins/orange-pin.svg');
  }
  if (damage_count > 40) {
    return require('@/assets/images/icons/pins/yellow-pin.svg');
  }
  if (damage_count > 30) {
    return require('@/assets/images/icons/pins/green-pin.svg');
  }
  if (damage_count > 20) {
    return require('@/assets/images/icons/pins/grey-pin.svg');
  }
  if (damage_count > 10) {
    return require('@/assets/images/icons/pins/brown-pin.svg');
  }
  if (damage_count > 0) {
    return require('@/assets/images/icons/pins/blue-pin.svg');
  }

  return require('@/assets/images/icons/pins/white-pin.svg');
};

export const getMakerColorCausaalCount = (marker: Marker) => {
  const causaal_count = marker.legenda.causaal_count;

  if (causaal_count > 70) {
    return require('@/assets/images/icons/pins/purple-pin.svg');
  }
  if (causaal_count > 60) {
    return require('@/assets/images/icons/pins/red-pin.svg');
  }
  if (causaal_count > 50) {
    return require('@/assets/images/icons/pins/orange-pin.svg');
  }
  if (causaal_count > 40) {
    return require('@/assets/images/icons/pins/yellow-pin.svg');
  }
  if (causaal_count > 30) {
    return require('@/assets/images/icons/pins/green-pin.svg');
  }
  if (causaal_count > 20) {
    return require('@/assets/images/icons/pins/grey-pin.svg');
  }
  if (causaal_count > 10) {
    return require('@/assets/images/icons/pins/brown-pin.svg');
  }
  if (causaal_count > 0) {
    return require('@/assets/images/icons/pins/blue-pin.svg');
  }

  return require('@/assets/images/icons/pins/white-pin.svg');
};

export const getMakerColorMaxPgv = (marker: Marker) => {
  const max_pgv = marker.legenda.max_pgv;

  if (max_pgv > 80) {
    return require('@/assets/images/icons/pins/purple-pin.svg');
  }
  if (max_pgv > 40) {
    return require('@/assets/images/icons/pins/red-pin.svg');
  }
  if (max_pgv > 20) {
    return require('@/assets/images/icons/pins/orange-pin.svg');
  }
  if (max_pgv > 8.5) {
    return require('@/assets/images/icons/pins/yellow-pin.svg');
  }
  if (max_pgv > 4) {
    return require('@/assets/images/icons/pins/blue-pin.svg');
  }
  if (max_pgv > 3) {
    return require('@/assets/images/icons/pins/green-pin.svg');
  }
  if (max_pgv > 2) {
    return require('@/assets/images/icons/pins/brown-pin.svg');
  }
  if (max_pgv > 0) {
    return require('@/assets/images/icons/pins/grey-pin.svg');
  }

  return require('@/assets/images/icons/pins/white-pin.svg');
};

export const getMakerColorPlanning: {[key: string]: string} = {
  unplanned: require('@/assets/images/icons/pins/purple-pin.svg'),
  no_answer: require('@/assets/images/icons/pins/yellow-pin.svg'),
  planned: require('@/assets/images/icons/pins/green-pin.svg'),
  not_needed_anymore: require('@/assets/images/icons/pins/grey-pin.svg'),
  vervolgopname: require('@/assets/images/icons/pins/orange-pin.svg'),
  nader_onderzoek: require('@/assets/images/icons/pins/black-pin.svg'),
  aanvullend_onderzoek: require('@/assets/images/icons/pins/brown-pin.svg'),
  heropname: require('@/assets/images/icons/pins/red-pin.svg'),
  no_action_required: require('@/assets/images/icons/pins/white-pin.svg'),
};

export const getEarthquakeMarker = (earthquake: Earthquake) => {
  let marker = 'marker_xs';

  if (earthquake.magnitude > 3.0) {
    marker = 'marker_xl';
  } else if (earthquake.magnitude > 2.4) {
    marker = 'marker_lg';
  } else if (earthquake.magnitude > 1.8) {
    marker = 'marker_sm';
  }

  return `https://tcmg.schadeexpertise-groningen.nl/img/markers/${marker}.png`;
};
