import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=47d115eb&"
import script from "./Reports.ts?vue&type=script&lang=ts&"
export * from "./Reports.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47d115eb')) {
      api.createRecord('47d115eb', component.options)
    } else {
      api.reload('47d115eb', component.options)
    }
    module.hot.accept("./Reports.vue?vue&type=template&id=47d115eb&", function () {
      api.rerender('47d115eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PeTAT/Tabs/Reports/Reports.vue"
export default component.exports