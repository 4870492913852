import { MapArea } from '@/models/MapArea';
import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<CreateMapAreaDialog>({})
export default class CreateMapAreaDialog extends Vue {
  protected isLoading = false;

  protected selectedName = '';

  protected close() {
    this.$emit('input', false);
  }

  protected create() {
    this.isLoading = true;

    const payload = {
      name: this.selectedName,
    };

    new MapArea()
      .create(payload)
      .then(() => {
        this.$emit('mapAreaCreated');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
