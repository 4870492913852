import { ReportPeTATFilters } from '@/components/PeTATFilters/PeTATFilters';
import Storage from '@/support/Storage';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MapFilterIncludes, BooleanNumberValue, MapVisibility } from '../../PeTAT';

@Component<Earthquakes>({})
export default class Earthquakes extends Vue {
  // #region @Props
  @Prop()
  protected filters!: ReportPeTATFilters;
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected pgvModels: PgvModel[] = [
    { name: '1% model', value: '1' },
    { name: '25% model', value: '25' },
    { name: '50% model', value: '50' },
  ];

  protected includes: MapFilterIncludes = {
    earthquakes: 0,
    gasfields: 0,
    gasfields_6km: 0,
    reports: 0,
    saltfields: 0,
    peilgebieden: 0,
    boezems: 0,
    boezems200m: 0,
    testgebieden: 0,
  };

  protected internalFilters: ReportPeTATFilters = {};

  protected visible: MapVisibility = {};
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.initialize();
  }

  protected initialize(): void {
    this.setInternalFilters();
    this.setStorageValues();
    this.includesChanged();
    this.visibilityChanged();
  }
  // #endregion

  // #region Class methods
  protected filtersChanged(): void {
    this.$emit('filtersChanged', this.internalFilters);
  }

  protected includesChanged(): void {
    this.$emit('includesChanged', this.includes);
  }

  protected visibilityChanged(): void {
    this.$emit('visibilityChanged', this.visible);
  }

  protected setInternalFilters(): void {
    this.internalFilters = { ...this.internalFilters, ...this.filters };
  }

  protected setStorageValues(): void {
    this.includes.earthquakes = this.storageValueToNumber(Storage.get('showEarthquakes'));
    this.includes.gasfields = this.storageValueToNumber(Storage.get('showGasfields'));
    this.includes.gasfields_6km = this.storageValueToNumber(Storage.get('showGasfields6Km'));
    this.includes.saltfields = this.storageValueToNumber(Storage.get('showSaltfields'));
    this.includes.peilgebieden = this.storageValueToNumber(Storage.get('showPeilgebieden'));
    this.includes.boezems = this.storageValueToNumber(Storage.get('showBoezems'));
    this.includes.boezems200m = this.storageValueToNumber(Storage.get('showBoezems200m'));
    this.includes.testgebieden = this.storageValueToNumber(Storage.get('showTestgebieden'));
    this.visible.earthquakeCenter = this.storageValueToNumber(Storage.get('showEarthquakeCenter'));
    this.visible.earthquakeRadius = this.storageValueToNumber(Storage.get('showEarthquakeRadius'));
  }

  protected storageValueToNumber(value: string | null): BooleanNumberValue {
    return value === '1' || value === 'true' ? 1 : 0;
  }

  protected toggleEarthquakes(): void {
    Storage.set('showEarthquakes', this.includes.earthquakes);
  }

  protected toggleGasfields(): void {
    Storage.set('showGasfields', this.includes.gasfields);
  }

  protected toggleGasfields6Km(): void {
    Storage.set('showGasfields6Km', this.includes.gasfields_6km);
  }

  protected toggleSaltfields(): void {
    Storage.set('showSaltfields', this.includes.saltfields);
  }

  protected toggleBoezems(): void {
    Storage.set('showBoezems', this.includes.boezems);
  }

  protected toggleBoezems200m(): void {
    Storage.set('showBoezems200m', this.includes.boezems200m);
  }

  protected toggleTestgebieden(): void {
    Storage.set('showTestgebieden', this.includes.testgebieden);
  }

  protected togglePeilgebieden(): void {
    Storage.set('showPeilgebieden', this.includes.peilgebieden);
  }

  protected toggleEarthquakeRadius(): void {
    Storage.set('showEarthquakeRadius', this.visible.earthquakeRadius);
  }

  protected toggleEarthquakeCenter(): void {
    Storage.set('showEarthquakeCenter', this.visible.earthquakeCenter);
  }

  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  @Watch('filters')
  protected fitelrsah(): void {
    this.setInternalFilters();
  }
  // #endregion
}

interface PgvModel {
  name: string;
  value: string;
}
