var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tw-flex tw-flex-col" }, [
        _c("h3", { staticClass: "tw-w-full tw-mb-16" }, [
          _vm._v("Data inladen:")
        ]),
        _c(
          "div",
          { staticClass: "tw-mb-16" },
          [
            _c("v-checkbox", {
              staticClass: "tw-p-0 tw-m-0",
              attrs: {
                "false-value": 0,
                "true-value": 1,
                label: "Rapporten",
                color: "primary",
                "hide-details": ""
              },
              on: {
                change: function($event) {
                  return _vm.includesChanged()
                }
              },
              model: {
                value: _vm.includes.reports,
                callback: function($$v) {
                  _vm.$set(_vm.includes, "reports", $$v)
                },
                expression: "includes.reports"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/2" },
          [
            _c("v-select", {
              attrs: {
                label: "Legenda",
                items: _vm.legendaTypes,
                "item-text": "label",
                "item-value": "value"
              },
              on: { change: _vm.selectedLegendaTypeChanged },
              model: {
                value: _vm.selectedLegendaType,
                callback: function($$v) {
                  _vm.selectedLegendaType = $$v
                },
                expression: "selectedLegendaType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "tw-h-px tw-w-full tw-bg-gray-200 tw-my-32" }),
      _c("PeTATFilters", {
        attrs: { defaultFilters: _vm.filters },
        on: { filterChanged: _vm.filtersChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }