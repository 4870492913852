var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "petat-container tw-h-full" }, [
    _c("div", { staticClass: "tw-flex tw-h-full" }, [
      _c(
        "div",
        {
          staticClass: "elementPanel tw-p-8 tw-mr-4",
          class: _vm.mapOnly ? "tw-w-full" : "tw-w-1/2 "
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "tw-w-full" },
                [
                  _c("v-progress-linear", {
                    staticClass: "tw-m-0",
                    attrs: { indeterminate: true }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "tw-h-full tw-relative",
              on: {
                keydown: function($event) {
                  return _vm.onKeyDown($event)
                },
                keyup: function($event) {
                  return _vm.onKeyUp($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "map-widget-container map-widget-container--legenda-icon",
                  on: {
                    click: function($event) {
                      _vm.isDisplayingLegenda = true
                    }
                  }
                },
                [_c("v-icon", [_vm._v("help_outline")])],
                1
              ),
              _vm.isDisplayingLegenda
                ? _c(
                    "div",
                    {
                      staticClass:
                        "map-widget-container map-widget-container--legenda"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-w-full tw-items-center tw-justify-between tw-pb-8"
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "tw-font-semibold tw-text-primary-400"
                            },
                            [_vm._v("Legenda")]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "tw-text-3xl",
                              on: {
                                click: function($event) {
                                  _vm.isDisplayingLegenda = false
                                }
                              }
                            },
                            [_vm._v("close")]
                          )
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "tw-h-px tw-w-full tw-bg-gray-200"
                      }),
                      _c(
                        "div",
                        { staticClass: "tw-w-full tw-pt-8" },
                        [
                          _c("PeTATLegenda", {
                            attrs: { legendaType: _vm.internalLegendaType }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.mapOnly
                ? _c(
                    "div",
                    {
                      staticClass:
                        "map-widget-container map-widget-container--select-area",
                      on: {
                        click: function($event) {
                          return _vm.selectReportInMapArea()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-justify-center tw-items-center"
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "tw-text-primary-500 tw-mr-8" },
                            [_vm._v("tab_unselected")]
                          ),
                          _c("span", { staticClass: "tw-text-primary-500" }, [
                            _vm._v("Selecteer gebied")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "gmap-map",
                {
                  ref: "petat",
                  staticClass: "tw-h-full tw-w-full",
                  attrs: {
                    id: "petat",
                    center: _vm.mapCenter,
                    zoom: _vm.defaultZoom,
                    options: {
                      zoomControl: true,
                      mapTypeControl: true,
                      scaleControl: true,
                      streetViewControl: true,
                      rotateControl: true,
                      fullscreenControl: true,
                      disableDefaultUi: false
                    }
                  },
                  on: {
                    click: function($event) {
                      return _vm.onMouseClick($event)
                    },
                    mousemove: function($event) {
                      return _vm.onMouseMove($event)
                    }
                  }
                },
                [
                  _vm._l(_vm.testgebieden, function(testgebied) {
                    return _c("GmapPolygon", {
                      key: testgebied.id,
                      attrs: {
                        paths: testgebied.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#FF0000",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm.newAreaPoints && _vm.newAreaPoints.length > 2
                    ? _c("GmapPolygon", {
                        key: _vm.newArea,
                        ref: "mapArea",
                        attrs: {
                          paths: _vm.newAreaPoints,
                          visible: true,
                          options: {
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#67A7C4",
                            fillOpacity: 0.3
                          }
                        },
                        on: {
                          click: function($event) {
                            return _vm.onMouseClick($event)
                          },
                          mousemove: function($event) {
                            return _vm.onMouseMove($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._l(_vm.boezems200m, function(boezem) {
                    return _c("GmapPolygon", {
                      key: boezem.id,
                      attrs: {
                        paths: boezem.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#F48B29",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm._l(_vm.boezems, function(boezem) {
                    return _c("GmapPolygon", {
                      key: boezem.id,
                      attrs: {
                        paths: boezem.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#1ee373",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm._l(_vm.peilgebieden, function(peilgebied) {
                    return _c("GmapPolygon", {
                      key: peilgebied.id,
                      attrs: {
                        paths: peilgebied.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#AC0D0D",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm._l(_vm.saltfields, function(saltfield) {
                    return _c("GmapPolygon", {
                      key: saltfield.id,
                      attrs: {
                        paths: saltfield.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#7d34eb",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm._l(_vm.gasfields6Km, function(gasfield) {
                    return _c("GmapPolygon", {
                      key: gasfield.id,
                      attrs: {
                        paths: gasfield.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#FBE6C2",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm._l(_vm.gasfields, function(gasfield) {
                    return _c("GmapPolygon", {
                      key: gasfield.id,
                      attrs: {
                        paths: gasfield.contour,
                        visible: true,
                        options: {
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#F0C929",
                          fillOpacity: 0.3
                        }
                      },
                      on: {
                        click: function($event) {
                          return _vm.onMouseClick($event)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm.activeMapAreaPoint
                    ? _c("GmapMarker", {
                        attrs: {
                          position: {
                            lat: _vm.activeMapAreaPoint.lat,
                            lng: _vm.activeMapAreaPoint.lng
                          },
                          clickable: false,
                          icon: require("@/assets/images/icons/pin.png")
                        }
                      })
                    : _vm._e(),
                  _vm.latitude && _vm.longitude
                    ? _c("GmapMarker", {
                        attrs: {
                          position: { lat: _vm.latitude, lng: _vm.longitude },
                          clickable: false,
                          draggable: true
                        },
                        on: {
                          dragend: function($event) {
                            return _vm.updateAddressCoordinates(
                              $event.latLng,
                              $event
                            )
                          },
                          click: function($event) {
                            return _vm.onMouseClick($event)
                          },
                          mousemove: function($event) {
                            return _vm.onMouseMove($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._l(_vm.reports, function(report, reportIndex) {
                    return _c("GmapMarker", {
                      key: "report-" + reportIndex,
                      attrs: {
                        position: report.position,
                        draggable: false,
                        clickable: true,
                        icon: _vm.getMarkerColor(report),
                        title: report.name
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleInfoWindow(report, reportIndex)
                        },
                        mousemove: function($event) {
                          return _vm.onMouseMove($event)
                        }
                      }
                    })
                  }),
                  _vm.visible.earthquakeCenter
                    ? _vm._l(_vm.earthquakes, function(earthquake, index) {
                        return _c("GmapMarker", {
                          key: index,
                          attrs: {
                            position: earthquake.center,
                            clickable: true,
                            icon: _vm.getEarthquakeMarker(earthquake),
                            title: earthquake.name
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleInfoWindow(earthquake, index)
                            },
                            mousemove: function($event) {
                              return _vm.onMouseMove($event)
                            }
                          }
                        })
                      })
                    : _vm._e(),
                  _vm.visible.earthquakeRadius
                    ? _vm._l(_vm.earthquakes, function(
                        earthquake,
                        earthquakeRadiusIndex
                      ) {
                        return _c("GmapCircle", {
                          key: "earthquakeRadius-" + earthquakeRadiusIndex,
                          attrs: {
                            center: earthquake.center,
                            radius: earthquake.radius,
                            visible: true,
                            options: _vm.getCircleOptions(earthquake),
                            clickable: false
                          },
                          on: {
                            click: function($event) {
                              return _vm.onMouseClick($event)
                            },
                            mousemove: function($event) {
                              return _vm.onMouseMove($event)
                            }
                          }
                        })
                      })
                    : _vm._e(),
                  _c(
                    "GmapInfoWindow",
                    {
                      attrs: {
                        options: _vm.infoOptions,
                        position: _vm.infoWindowPos,
                        opened: _vm.infoWinOpen
                      },
                      on: {
                        closeclick: function($event) {
                          _vm.infoWinOpen = false
                        }
                      }
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.infoContent) }
                      })
                    ]
                  )
                ],
                2
              ),
              _c(
                "v-snackbar",
                {
                  staticClass: "snackbar snackbar--fullwidth",
                  attrs: { timeout: 0, absolute: "" },
                  model: {
                    value: _vm.isDisplayingSnackbar,
                    callback: function($$v) {
                      _vm.isDisplayingSnackbar = $$v
                    },
                    expression: "isDisplayingSnackbar"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-flex tw-justify-between tw-items-center tw-w-full"
                    },
                    [
                      _c("div", { staticClass: "tw-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tw-flex tw-flex-col tw-px-24 tw-py-16"
                          },
                          [
                            _c("h2", [
                              _vm._v(
                                _vm._s(_vm.selectedReports.length) +
                                  " Geselecteerde rapport(en)"
                              )
                            ]),
                            _vm.project
                              ? [
                                  _c("span", [
                                    _vm._v(
                                      "Wat wil je met de " +
                                        _vm._s(_vm.selectedReports.length) +
                                        " geselecteerde rapport(en) doen?"
                                    )
                                  ]),
                                  _c("v-checkbox", {
                                    staticClass: "tw-m-0 tw-p-0 tw-pt-8",
                                    attrs: {
                                      label: "Forceer toevoegen",
                                      "hide-details": "",
                                      color: "#a09b1b"
                                    },
                                    model: {
                                      value: _vm.forceAdd,
                                      callback: function($$v) {
                                        _vm.forceAdd = $$v
                                      },
                                      expression: "forceAdd"
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-justify-center tw-items-center"
                        },
                        [
                          _vm.project
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-flex tw-justify-center tw-items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                    tw-bg-success-300\n                    tw-rounded-full\n                    tw-flex\n                    tw-justify-center\n                    tw-items-center\n                    tw-p-16\n                    tw-mx-8\n                    tw-cursor-pointer\n                    hover:tw-bg-opacity-75\n                  ",
                                      on: {
                                        click: function($event) {
                                          return _vm.addSelectionToProject()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("add")])],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                    tw-bg-error-300\n                    tw-rounded-full\n                    tw-flex\n                    tw-justify-center\n                    tw-items-center\n                    tw-p-16\n                    tw-mx-8\n                    tw-cursor-pointer\n                    hover:tw-bg-opacity-75\n                  ",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeSelectionFromProject()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "tw-w-64 tw-flex tw-justify-center"
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.selectedReports = []
                                    }
                                  }
                                },
                                [_vm._v("close")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      !_vm.mapOnly
        ? _c("div", { staticClass: "elementPanel tw-p-0 tw-w-1/2 tw-ml-4" }, [
            _c(
              "div",
              { staticClass: "elementPanel-absolute-button tw-right-0" },
              [
                _c(
                  "MiButton",
                  {
                    attrs: {
                      icon: "landscape",
                      disabled: _vm.isDisabled,
                      color: "primary",
                      compact: "true",
                      small: "true"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.generateMap()
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isLoading ? "Bezig met genereren" : "Genereer Map"
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "elementPanel tw-p-0 tw-h-full" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: { "slider-color": "secondary" },
                    model: {
                      value: _vm.activeTab,
                      callback: function($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab"
                    }
                  },
                  [
                    _vm._l(_vm.tabs, function(tab, index) {
                      return [
                        !tab.hidden
                          ? _c(
                              "v-tab",
                              {
                                key: index,
                                staticClass: "tab__filled",
                                class: { active: _vm.activeTab === tab.key },
                                attrs: { href: "#" + tab.key }
                              },
                              [_vm._v(" " + _vm._s(tab.name) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                ),
                _c(
                  "v-tabs-items",
                  {
                    staticClass: "tw-h-full",
                    model: {
                      value: _vm.activeTab,
                      callback: function($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tw-overflow-auto",
                        class: { "tw-hidden": _vm.activeTab !== "earthquakes" },
                        staticStyle: { height: "calc(100% - 48px)" }
                      },
                      [
                        _c("Earthquakes", {
                          staticClass: "tw-py-8 tw-px-16",
                          attrs: { filters: _vm.filters },
                          on: {
                            includesChanged: _vm.includesChanged,
                            filtersChanged: _vm.filtersChanged,
                            visibilityChanged: _vm.visibilityChanged
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tw-overflow-auto",
                        class: {
                          "tw-hidden": _vm.activeTab !== "trillingstool"
                        },
                        staticStyle: { height: "calc(100% - 48px)" }
                      },
                      [
                        _c("Trillingstool", {
                          staticClass: "tw-py-8 tw-px-16",
                          attrs: {
                            filters: _vm.filters,
                            longitude: _vm.longitude,
                            latitude: _vm.latitude
                          },
                          on: {
                            includesChanged: _vm.includesChanged,
                            filtersChanged: _vm.filtersChanged
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tw-overflow-auto",
                        class: { "tw-hidden": _vm.activeTab !== "reports" },
                        staticStyle: { height: "calc(100% - 48px)" }
                      },
                      [
                        _c("Reports", {
                          staticClass: "tw-py-8 tw-px-16",
                          attrs: {
                            filters: _vm.filters,
                            project: _vm.project,
                            legendaType: _vm.internalLegendaType
                          },
                          on: {
                            includesChanged: _vm.includesChanged,
                            filtersChanged: _vm.filtersChanged,
                            selectedLegendaTypeChanged:
                              _vm.selectedLegendaTypeChanged
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tw-overflow-auto",
                        class: { "tw-hidden": _vm.activeTab !== "areas" },
                        staticStyle: { height: "calc(100% - 48px)" }
                      },
                      [
                        _c("Areas", {
                          staticClass: "tw-py-8 tw-px-16",
                          attrs: {
                            project: _vm.project,
                            legendaType: _vm.internalLegendaType,
                            selectedArea: _vm.selectedArea,
                            newAreaPoints: _vm.newAreaPoints,
                            activeMapAreaPoint: _vm.activeMapAreaPoint,
                            activeMapAreaIndex: _vm.activeMapAreaIndex,
                            latitude: _vm.mapLatitude,
                            longitude: _vm.mapLongitude,
                            isActiveLockCoordinates: _vm.lockCoordinates,
                            isActiveSelectionTool: _vm.selectionTool,
                            isActiveCoordinatesTool: _vm.coordinatesTool
                          },
                          on: {
                            "update:selectedArea": function($event) {
                              _vm.selectedArea = $event
                            },
                            "update:selected-area": function($event) {
                              _vm.selectedArea = $event
                            },
                            "update:newAreaPoints": function($event) {
                              _vm.newAreaPoints = $event
                            },
                            "update:new-area-points": function($event) {
                              _vm.newAreaPoints = $event
                            },
                            "update:activeMapAreaPoint": function($event) {
                              _vm.activeMapAreaPoint = $event
                            },
                            "update:active-map-area-point": function($event) {
                              _vm.activeMapAreaPoint = $event
                            },
                            "update:activeMapAreaIndex": function($event) {
                              _vm.activeMapAreaIndex = $event
                            },
                            "update:active-map-area-index": function($event) {
                              _vm.activeMapAreaIndex = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }