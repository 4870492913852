import { Component, Vue, Prop } from 'vue-property-decorator';
import { styles } from '@/support/Style';
import { DateTime } from 'luxon';
import { ReportStatusMap, excludePlanningStatuses } from '@/support/ReportStatus';
import { userRoles } from '@/models/User';

@Component<PeTATFilters>({
  components: {},
})
export default class PeTATFilters extends Vue {
  @Prop()
  protected defaultFilters!: ReportPeTATFilters;

  protected reportStatusMap: ReportStatusMap[] = excludePlanningStatuses();

  protected filters: ReportPeTATFilters = {
    from: '',
    until: '',
    postcode: '9726AD',
    number: '10',
    number_add: '',
    magnitude_min: '1.0',
    magnitude_max: '6.0',
    pgv: '2.0',
    pct: '1',
    statuses: [
      'downloaded',
    ],
    applicant_submitted_at: {},
    downloaded_at: {},
    payout_total: {
      from: 0,
      to: 1000000,
    },
    filterPayoutTotal: false,
  };

  protected userRoles = userRoles;

  protected mounted() {
    this.initalizeFilters();
  }

  protected initalizeFilters() {
    this.filters = { ...this.filters, ...this.defaultFilters };
    this.filterChanged();
  }

  protected filterChanged() {
    this.periodFilters.map((periodFilter) => {
      if (periodFilter.enabled) {
        this.filters[periodFilter.key] = {
          from: periodFilter.from,
          to: periodFilter.to,
        };
      } else {
        this.filters[periodFilter.key] = {};
      }
    });
    this.$emit('filterChanged', this.filters);
  }

  protected getColor(key: string): string {
    return styles[key];
  }

  protected get periodFilters(): ReportPeTATPeriodFilter[] {
    return [
      {
        title: 'Aanvraag datum',
        key: 'applicant_submitted_at',
        from: DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
        to: DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
        enabled: false,
      },
      {
        title: 'Datum ingepland',
        key: 'assigned_at',
        from: DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
        to: DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
        enabled: false,
      },
      {
        title: 'Opnamedatum',
        key: 'planned_at',
        from: DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
        to: DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
        enabled: false,
      },
      {
        title: 'Verstuurd aan aanvrager',
        key: 'downloaded_at',
        from: DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
        to: DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
        enabled: true,
      },
    ];
  }
}

export interface LegandaItem {
  value: number;
  label: string;
  in_project: boolean;
}

export interface ReportPeTATFilters {
  [key:string]: any;
  from?: string;
  until?: string;
  postcode?: string;
  number?: string;
  number_add?: string;
  magnitude_min?: string;
  magnitude_max?: string;
  pgv?: string;
  pct?: string;
  report_handlers?: string[],
  statuses?: string[],
  organizations?: string[],
  mediators?: string[],
  types?: string[],
  tags?: string[],
  skills?: string[],
  departments?: string[],
  applicant_submitted_at?: {
    from?: string;
    to?: string;
  },
  downloaded_at?: {
    from?: string;
    to?: string;
  };
  payout_total?: {
    from?: number,
    to?: number
  };
  filterPayoutTotal?: boolean;
  overdue?: boolean;
  overdue_kpi?: boolean;
  is_prio?: boolean;
  is_special?: boolean;
  filterApplicantSubmittedAt?: boolean;
  filterDownloadedAt?: boolean;
}

export interface ReportPeTATPeriodFilter {
  title: string;
  key: string;
  from: string;
  to: string;
  enabled: boolean;
}
