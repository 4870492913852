var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-flex tw-flex-col tw-flex-wrap" },
    _vm._l(_vm.legendaItems, function(legendaItem, i) {
      return _c("div", { key: i, staticClass: "tw-flex tw-flex-row tw-pb-8" }, [
        _c("div", { staticClass: "tw-pr-8" }, [
          _c("img", { attrs: { src: _vm.getMarkerColor(legendaItem) } })
        ]),
        _c("div", [_vm._v(" " + _vm._s(legendaItem.label) + " ")])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }