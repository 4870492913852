var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [_vm._v("Nieuw Gebied aanmaken")]),
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.isLoading
                        ? _c("LoaderCard", {
                            attrs: {
                              flat: "",
                              type: "spinner--center",
                              minHeight: "250px"
                            }
                          })
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c("v-text-field", {
                              attrs: {
                                placeholder: "Gebied naam",
                                label: "Gebied naam"
                              },
                              model: {
                                value: _vm.selectedName,
                                callback: function($$v) {
                                  _vm.selectedName = $$v
                                },
                                expression: "selectedName"
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fadedText link mr-3",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: !_vm.selectedName,
                                  color: "primary",
                                  small: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.create($event)
                                  }
                                }
                              },
                              [_vm._v(" Aanmaken ")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }