import { Project } from '@/models/Project';
import { Component, Vue, Watch, Prop, PropSync } from 'vue-property-decorator';
import { getMakerColor, getEarthquakeMarker, getActiveMarker } from '@/support/GoogleMapHelpers';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { MapArea, Position } from '@/models/MapArea';
import CreateMapAreaDialog from '@/components/PeTAT/CreateMapAreaDialog.vue';
import { getReportInfoWindowContent, getEarthquakeInfoWindowContent } from '@/support/InfoWindow';
import { userRoles } from '@/models/User';
import PeTATLegenda from '@/components/PeTATLegenda/PeTATLegenda.vue';

// TABS
import Earthquakes from './Tabs/Earthquakes/Earthquakes.vue';
import Reports from './Tabs/Reports/Reports.vue';
import Trillingstool from './Tabs/Trillingstool/Trillingstool.vue';
import Areas from './Tabs/Areas/Areas.vue';
import { ReportPeTATFilters } from '../PeTATFilters/PeTATFilters';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Qs = require('qs');

@Component<PeTAT>({
  components: {
    CreateMapAreaDialog,
    Earthquakes,
    Reports,
    Trillingstool,
    Areas,
    PeTATLegenda,
  },
})
export default class PeTAT extends Vue {
  // #region @Props
  @Prop()
  protected response!: EffectAreaResponse;

  @PropSync('legendaType', { required: true })
  protected internalLegendaType!: PetatLegendaTypes;

  @Prop()
  protected highlighted!: any;

  @Prop({ default: false })
  protected mapOnly!: boolean;

  @Prop()
  protected defaultFilters!: ReportPeTATFilters;

  @Prop()
  protected defaultIncludes!: MapFilterIncludes;

  @Prop({ default: false })
  protected isLoading!: boolean;

  @Prop()
  protected project!: Project;

  @Prop({ default: () => ({
    lat: 53.250748,
    lng: 6.513490,
  }) })
  protected mapCenter!: LatLng;

  @Prop({ default: 10 })
  protected defaultZoom!: number;
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected gmap!: any;

  protected tempLegendaType: PetatLegendaTypes | null = null;

  protected gribBoundingBox: any = null;

  protected selectedReports: Marker[] | ReportEffectgebied[]= [];

  protected positionClick = 0;

  protected lockCoordinates = false;

  protected firstPos: ClickPosition = {
    set: false,
  };

  protected secondPos: ClickPosition = {
    set: false,
  };

  protected isDisplayingLegenda = false;

  protected forceAdd = false;

  protected editFilters = false;

  protected selectionTool = false;

  protected coordinatesTool = false;

  protected activeMapAreaPoint: Position | null = null;

  protected activeTab = 'earthquakes';

  protected latitude: number | null = null;

  protected longitude: number | null = null;

  protected mapLatitude: number | null = 0;

  protected mapLongitude: number | null = 0;

  protected filters: ReportPeTATFilters = {
    from: '2012-01-01',
    until: '2022-12-31',
    postcode: '9726AD',
    number: '10',
    number_add: '',
    magnitude_min: '1.0',
    magnitude_max: '6.0',
    pgv: '2.0',
    pct: '1',
    statuses: [
      'downloaded',
    ],
    applicant_submitted_at: {},
    downloaded_at: {},
    payout_total: {
      from: 0,
      to: 1000000,
    },
    filterApplicantSubmittedAt: false,
    filterDownloadedAt: true,
    filterPayoutTotal: false,
  };

  protected filterIncludes: MapFilterIncludes = {};

  protected visible: MapVisibility = {};

  protected infoWinOpen = false;

  protected infoContent = 'Loading...';

  protected currentMidx = 0;

  protected infoWindowPos: any = {
    lat: 0,
    lng: 0,
  };

  protected infoOptions: any = {
    pixelOffset: {
      width: 0,
      height: - 35,
    },
  }

  protected userRoles = userRoles;

  protected earthquakes: Earthquake[] = [];

  protected gasfields: Gasfield[] = [];

  protected gasfields6Km: Gasfield[] = [];

  protected saltfields: Gasfield[] = [];

  protected boezems: Gasfield[] = [];

  protected boezems200m: Gasfield[] = [];

  protected testgebieden: Gasfield[] = [];

  protected peilgebieden: Gasfield[] = [];

  protected reports: ReportEffectgebied[] = [];

  protected trillingstoolItems1pct: TrillingstoolItem[] = [];

  protected trillingstoolItems25pct: TrillingstoolItem[] = [];

  protected trillingstoolItems50pct: TrillingstoolItem[] = [];

  protected address: Address = {};

  protected newArea = '';

  protected newAreaPoints: Position[] = [];

  protected editMapArea = false;

  protected selectedArea: MapArea | null = null;

  protected activeMapAreaIndex = 0;
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted() {
    this.initalizeFilters();
    this.initalizeIncludes();
    this.generateMap();
    this.initializeReportSelector();

    if (this.response) {
      this.parseResponse();
    }

    if (this.project) {
      this.initializeProjectMapArea();
    }
  }

  protected async initializeProjectMapArea() {
    if (! this.project.map_area || ! this.project.map_area.id) {
      return;
    }

    const mapArea = await (new MapArea()).find(this.project.map_area.id);

    this.handleSelectedArea(mapArea);
  }

  protected initializeReportSelector() {
    (this.$refs.petat as any).$mapPromise.then((map: any) => {
      const google = (window as any).google;

      this.gmap = map;
      this.gribBoundingBox = new google.maps.Rectangle({
        map,
        bounds: null,
        fillOpacity: 0.15,
        strokeWeight: 0.9,
        clickable: false,
      });
    });
  }

  protected initalizeFilters() {
    this.filters = { ...this.filters, ...this.defaultFilters };
  }

  protected initalizeIncludes() {
    this.filterIncludes = { ...this.filterIncludes, ...this.defaultIncludes };
  }

  // #endregion

  // #region Class methods
  protected handleSelectedArea(area: MapArea): void {
    if (! area || ! area.contour) {
      this.selectedArea = null;
      this.activeMapAreaPoint = null;
      this.newAreaPoints = [];
      return;
    }

    this.selectedArea = area;
    this.newAreaPoints = area.contour;
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.altKey && event.shiftKey) {
      return;
    }

    if (event.code == 'ShiftLeft') {
      this.selectionTool = true;
    }
    if (event.code == 'AltLeft') {
      this.coordinatesTool = true;
    }
  }

  protected onKeyUp(event: KeyboardEvent) {
    if (event.altKey && event.shiftKey) {
      return;
    }

    if (event.code == 'ShiftLeft') {
      this.selectionTool = false;
    }
    if (event.code == 'AltLeft') {
      this.coordinatesTool = false;
    }
    if (event.code == 'KeyL') {
      this.lockCoordinates = ! this.lockCoordinates;
    }
  }

  protected onMouseClick(event: GoogleMouseEvent) {
    if (this.coordinatesTool) {
      const element = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.activeMapAreaIndex += 1;
      this.newAreaPoints.splice(this.activeMapAreaIndex, 0, element);
      this.printMapAreaCoordindates();
    } else if (this.selectionTool) {
      this.positionClick = (this.positionClick + 1) % 3;

      if (this.positionClick === 1) {
        this.firstPos.set = true;
        this.firstPos.latLng = event.latLng;
      } else if (this.positionClick === 2) {
        this.secondPos.set = true;
        this.secondPos.latLng = event.latLng;

        this.getSelectedReports();
      } else {
        this.firstPos.set = false;
        this.secondPos.set = false;
        this.gribBoundingBox.setBounds(null);
      }
    }
  }

  protected onMouseMove(event: GoogleMouseEvent) {
    const google = (window as any).google;

    if (this.selectionTool && this.positionClick == 1) {
      const newbounds = new google.maps.LatLngBounds(this.firstPos.latLng, event.latLng);

      if (this.gribBoundingBox !== null) {
        this.gribBoundingBox.setBounds(newbounds);
      }
    }

    if (this.lockCoordinates) {
      return;
    }

    this.mapLatitude = event.latLng.lat();
    this.mapLongitude = event.latLng.lng();
  }

  protected getSelectedReports() {
    const southWest = {
      lat: this.gribBoundingBox.getBounds().getSouthWest().lat(),
      lng: this.gribBoundingBox.getBounds().getSouthWest().lng(),
    };
    const northEast = {
      lat: this.gribBoundingBox.getBounds().getNorthEast().lat(),
      lng: this.gribBoundingBox.getBounds().getNorthEast().lng(),
    };

    this.selectedReports = this.reports.filter((report) => {
      if (! report.position?.lat || ! report.position?.lng) { return false; }
      return this.isInLat(southWest.lat, northEast.lat, report.position?.lat) && this.isInLng(southWest.lng, northEast.lng, report.position?.lng);
    });
  }

  protected isInLat(soundWestLat: number, northEastLat: number, pointLat: number): boolean {
    return pointLat >= northEastLat && pointLat <= soundWestLat;
  }

  protected isInLng(soundWestLng: number, northEastLng: number, pointLng: number): boolean {
    return pointLng >= soundWestLng && pointLng <= northEastLng;
  }

  protected selectReportInMapArea() {
    this.positionClick = 2;

    const google = (window as any).google;
    const mapArea = new google.maps.Polygon({
      paths: this.newAreaPoints,
    });

    this.selectedReports = this.reports.filter((report) => {
      if (! report.position?.lat || ! report.position?.lng) { return false; }
      const reportPosition = new google.maps.LatLng(report.position.lat, report.position.lng);

      return google.maps.geometry.poly.containsLocation(reportPosition, mapArea);
    });
  }

  protected printMapAreaCoordindates() {
    this.newArea = 'MULTIPOLYGON(((\n';
    this.newAreaPoints.map((point: Position, index: number) => {
      this.newArea += `${point.lat} ${point.lng}`;
      if (index < this.newAreaPoints.length - 1) {
        this.newArea += ',\n';
      }
    });
    this.newArea += ')))';
  }

  protected parseResponse() {
    this.earthquakes = this.response.data.earthquakes as Earthquake[];

    const address = this.response.data.address.results[0];
    if (address) {
      this.address = address ? address as Address : {};
      this.latitude = address.lat || 0;
      this.longitude = address.lng || 0;
    } else {
      this.address = address ? address as Address : {};
      this.latitude = null;
      this.longitude = null;
    }
    this.gasfields = this.response.data.gasfields as Gasfield[];
    this.gasfields6Km = this.response.data.gasfields_6km as Gasfield[];
    this.saltfields = this.response.data.saltfields as Gasfield[];
    this.peilgebieden = this.response.data.peilgebieden as Gasfield[];
    this.boezems = this.response.data.boezems as Gasfield[];
    this.boezems200m = this.response.data.boezems200m as Gasfield[];
    this.testgebieden = this.response.data.testgebieden as Gasfield[];
    this.reports = this.response.data.reports as ReportEffectgebied[];
  }

  protected filtersChanged(filters: ReportPeTATFilters) {
    this.filters = { ...this.filters, ...filters };
  }

  protected includesChanged(filterIncludes: MapFilterIncludes) {
    this.filterIncludes = { ...this.filterIncludes, ...filterIncludes };
  }

  protected visibilityChanged(visible: MapVisibility) {
    this.visible = { ...this.visible, ...visible };
  }

  protected selectedLegendaTypeChanged(legendaType: PetatLegendaTypes) {
    this.tempLegendaType = legendaType;
  }

  protected generateMap() {
    if (this.filters.filterPayoutTotal && this.filters.payout_total) {
      this.filters.payout_total = {
        from: this.filters.payout_total.from,
        to: this.filters.payout_total.to,
      };
    }

    this.internalLegendaType = this.tempLegendaType || this.internalLegendaType;
    const filterIncludes = { ...this.filterIncludes, legenda: this.tempLegendaType };

    const payload = Qs.stringify({
      filters: this.filters,
      project: this.project ? this.project.id : undefined,
      with: filterIncludes,
    }, { arrayFormat: 'brackets', prefix: 'filters' });

    this.editFilters = false;
    this.$emit('initialize', payload);
  }

  protected getCircleOptions(earthquake: Earthquake) {
    const large = earthquake.magnitude > 2.9;
    const medium = earthquake.magnitude > 1.9 && earthquake.magnitude < 3.0;

    const color = earthquake.gasfield ? 'red' : 'grey';

    let stokeWeight = 1;
    if (medium) {
      stokeWeight = 2;
    }
    if (large) {
      stokeWeight = 3;
    }

    return {
      strokeColor: color,
      strokeOpacity: 1,
      strokeWeight: stokeWeight,
      fillColor: 'orange',
      fillOpacity: 0.05,
    };
  }

  public updateAddressCoordinates(latlng: LatLng) {
    this.latitude = latlng.lat();
    this.longitude = latlng.lng();
    this.filters.postcode = '';
    this.filters.number = '';
    this.filters.number_add = '';
  }

  protected iconType(marker: Marker): PetatLegendaTypes | 'active' {
    return this.highlighted && this.highlighted.id === marker.id ? 'active' : this.internalLegendaType;
  }

  protected getMarkerColor(marker: Marker) {
    if (this.iconType(marker) === 'active') {
      return getActiveMarker();
    }

    return getMakerColor(marker, this.iconType(marker) as PetatLegendaTypes);
  }

  protected getEarthquakeMarker(earthquake: Earthquake) {
    return getEarthquakeMarker(earthquake);
  }

  protected async toggleInfoWindow(marker: Marker, idx: number) {
    if (marker.center) {
      this.infoWindowPos = marker.center;
      this.infoContent = getEarthquakeInfoWindowContent(marker);
    } else {
      this.infoWindowPos = marker.position;
      this.infoContent = await this.getReportInfoWindowContent(marker);
    }

    // check if its the same marker that was selected if yes toggle
    if (this.currentMidx == idx) {
      this.infoWinOpen = ! this.infoWinOpen;
    } else {
      // if different marker set infowindow to open and reset current marker index
      this.infoWinOpen = true;
      this.currentMidx = idx;
    }

    if (this.internalLegendaType === 'project') {
      this.$nextTick(() => {
        const addListner = document.getElementById('addToProject');
        const removeListner = document.getElementById('removeFromProject');

        if (addListner) {
          addListner.addEventListener('click', () => {
            this.addToProject(marker);
          });
        }
        if (removeListner) {
          removeListner.addEventListener('click', () => {
            this.removeFromProject(marker);
          });
        }
      });
    }
  }

  public addSelectionToProject() {
    (this.selectedReports as Marker[]).map((marker: Marker) => {
      marker.legenda.in_project = true;
    });
    this.positionClick = 0;

    this.$emit('addToProject', this.selectedReports, this.forceAdd);
  }

  public addToProject(marker: Marker) {
    marker.legenda.in_project = ! marker.legenda.in_project;
    this.infoWinOpen = false;

    this.$emit('addToProject', [marker]);
  }

  public removeSelectionFromProject() {
    (this.selectedReports as Marker[]).map((marker: Marker) => {
      marker.legenda.in_project = false;
    });
    this.positionClick = 0;

    this.$emit('removeFromProject', this.selectedReports);
  }

  public removeFromProject(marker: Marker) {
    marker.legenda.in_project = ! marker.legenda.in_project;
    this.infoWinOpen = false;

    this.$emit('removeFromProject', [marker]);
  }
  // #endregion

  // #region Async methods
  protected async loadReport(id: string) {
    const response = await new Report().refactor()
      .include([
        'organization',
        'type',
        'finance',
        'answers',
        'address',
        'expert',
        'second_expert',
        'organization_tc',
        'organization_expert',
        'pgv_report',
      ])
      .find(id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    return response || null;
  }

  protected async getReportInfoWindowContent(marker: Marker) : Promise<string> {
    const report = await this.loadReport(marker.id);

    const windowContent = getReportInfoWindowContent(marker, report);
    const addProjectButton = '| <a id="addToProject">TOEVOEGEN</a>';
    const removeProjectButton = '| <a id="removeFromProject">VERWIJDEREN</a>';

    const projectButton = `
      ${this.internalLegendaType === 'project' && ! this.mapOnly && ! marker.legenda.in_project ? addProjectButton : ''}
      ${this.internalLegendaType === 'project' && ! this.mapOnly && marker.legenda.in_project ? removeProjectButton : ''}
    `;

    return `<div class="">
      <div>
        <div class="m-2">
          ${windowContent}
        </div>
        <div class="m-2">
          <div class="m-2">
            <span style="font-weight: bold;">Acties:</span>
          </div>
          <a href="/reports/${marker.id}" target="_blank">Details</a>
          ${projectButton}
        </div>
      </div>
    </div>`;
  }

  // #endregion

  // #region Getters & Setters
  protected get isDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }

    return ! this.filters.from
      || ! this.filters.until
      || ! this.filters.magnitude_min
      || ! this.filters.magnitude_max;
  }

  protected get isDisplayingSnackbar(): boolean {
    if (this.positionClick !== 2) {
      return false;
    }

    return this.selectedReports.length > 0;
  }

  protected get tabs(): TabItem[] {
    return [
      {
        name: 'Aardbevingen',
        key: 'earthquakes',
        hidden: false,
      },
      {
        name: 'Trillingstool',
        key: 'trillingstool',
        hidden: false,
      },
      {
        name: 'Rapporten',
        key: 'reports',
        hidden: false,
      },
      {
        name: 'Gebieden',
        key: 'areas',
        hidden: false,
      },
    ];
  }

  // #endregion

  // #region @Watchers
  @Watch('response')
  protected responseChanged() {
    if (this.response) {
      this.parseResponse();
    }
  }
  // #endregion
}

export interface Earthquake {
  center: { lat: number, lng: number };
  lat: number;
  lng: number;
  magnitude: number;
  name: string;
  occurred_at: string;
  gasfield: boolean;
  radius: number;
}

export interface TrillingstoolItem {
  magnitude: number;
  radius: number;
  formula: string;
}

export interface Address {
  areacode?: string;
  city?: string;
  construction_year?: number;
  functions?: string[];
  lat?: number;
  lng?: number;
  municipality?: string;
  nl_sixpp?: string;
  province?: string;
  street?: string;
  street_nen5825?: string;
  streetnumbers?: string;
  surface?: number;
}

export interface Gasfield {
  name: string;
  contour: string[];
}

export interface ReportEffectgebied {
  id: string;
  name: string;
  type: number;
  payout_total: number;
  position?: Position;
}

export interface LegandaItem {
  value: number;
  label: string;
  in_project: boolean;
}

export interface Marker {
  id: string;
  legenda: {
    planning_status: string;
    payout_total: number;
    damage_count: number;
    causaal_count: number;
    max_pgv: number;
    in_project: boolean;
  };
  name: string;
  position: Object;
  center: Object;
}

interface LatLng {
  lat: ()=> number;
  lng: ()=> number;
}

interface GoogleMouseEvent {
  domEvent: MouseEvent;
  latLng: LatLng;
  pixel: {
    x: number;
    y: number;
  }
}

interface TabItem {
  key: string;
  name: string;
  hidden: boolean;
}

export interface MapFilterIncludes {
  reports?: BooleanNumberValue;
  earthquakes?: BooleanNumberValue;
  gasfields?: BooleanNumberValue;
  gasfields_6km?: BooleanNumberValue;
  saltfields?: BooleanNumberValue;
  peilgebieden?: BooleanNumberValue;
  boezems?: BooleanNumberValue;
  boezems200m?: BooleanNumberValue;
  testgebieden?: BooleanNumberValue;
}

export interface MapVisibility {
  earthquakeCenter?: BooleanNumberValue;
  earthquakeRadius?: BooleanNumberValue;
}

interface EffectAreaResponse {
  data: {
    address: {
      results: Address[];
      status: string;
    };
    earthquakes: Earthquake[];
    gasfields: Gasfield[];
    gasfields_6km: Gasfield[];
    saltfields: Gasfield[];
    boezems: Gasfield[];
    boezems200m: Gasfield[];
    testgebieden: Gasfield[];
    peilgebieden: Gasfield[];
    reports: ReportEffectgebied[];
    trillingstool_matrix: {
      '1pct': TrillingstoolItem[];
      '25pct': TrillingstoolItem[];
      '50pct': TrillingstoolItem[];
    }
  }
}

export type BooleanNumberValue = 0 | 1;

export enum PetatLegendaTypes {
  PAYOUT_TOTAL = 'payout_total',
  DAMAGE_COUNT = 'damage_count',
  CAUSAAL_COUNT = 'causaal_count',
  STATUS = 'status',
  PLANNING = 'planning',
  PROJECT = 'project',
  MAX_PGV = 'max_pgv',
}

interface ClickPosition {
  set: boolean;
  latLng?: LatLng;
}
