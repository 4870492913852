import Storage from '@/support/Storage';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PeTATFilters from '@/components/PeTATFilters/PeTATFilters.vue';
import { ReportPeTATFilters } from '@/components/PeTATFilters/PeTATFilters';
import { Project } from '@/models/Project';
import { BooleanNumberValue, MapFilterIncludes, PetatLegendaTypes } from '../../PeTAT';

@Component<Reports>({
  components: {
    PeTATFilters,
  },
})
export default class Reports extends Vue {
  // #region @Props
  @Prop()
  protected project!: Project;

  @Prop()
  protected filters!: ReportPeTATFilters;

  @Prop()
  protected legendaType!: PetatLegendaTypes;
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected includes: MapFilterIncludes = {};

  protected selectedLegendaType = this.legendaType;
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.initialize();
  }

  protected initialize(): void {
    this.includesChanged();
  }
  // #endregion

  // #region Class methods
  protected filtersChanged(filters: ReportPeTATFilters): void {
    this.$emit('filtersChanged', filters);
  }

  protected includesChanged(): void {
    this.$emit('includesChanged', this.includes);
  }

  protected selectedLegendaTypeChanged() {
    this.$emit('selectedLegendaTypeChanged', this.selectedLegendaType);
  }

  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  protected get legendaTypes() {
    const legendaTypes = [
      {
        label: 'Schadebedrag',
        value: PetatLegendaTypes.PAYOUT_TOTAL,
      },
      {
        label: 'Aantal schades',
        value: PetatLegendaTypes.DAMAGE_COUNT,
      },
      {
        label: 'Aantal causale schades',
        value: PetatLegendaTypes.CAUSAAL_COUNT,
      },
      {
        label: 'Status',
        value: PetatLegendaTypes.STATUS,
      },
      {
        label: 'Max PGV (1%)',
        value: PetatLegendaTypes.MAX_PGV,
      },
    ];

    if (this.project) {
      legendaTypes.push({
        label: 'In/uit project',
        value: PetatLegendaTypes.PROJECT,
      });
    }

    return legendaTypes;
  }
  // #endregion

  // #region @Watchers
  // #endregion
}
