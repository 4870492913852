var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tw-flex tw-flex-wrap" },
      [
        _c("h3", { staticClass: "tw-w-full" }, [_vm._v("Adres")]),
        _c("v-text-field", {
          staticClass: "tw-w-1/3 tw-pr-8",
          attrs: { "hide-details": "", label: "Postcode" },
          on: {
            change: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.postcode,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "postcode", $$v)
            },
            expression: "internalFilters.postcode"
          }
        }),
        _c("v-text-field", {
          staticClass: "tw-w-1/3 tw-px-8",
          attrs: { "hide-details": "", label: "Huisnummer" },
          on: {
            change: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.number,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "number", $$v)
            },
            expression: "internalFilters.number"
          }
        }),
        _c("v-text-field", {
          staticClass: "tw-w-1/3 tw-pl-8",
          attrs: { "hide-details": "", label: "Toevoeging" },
          on: {
            change: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.number_add,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "number_add", $$v)
            },
            expression: "internalFilters.number_add"
          }
        }),
        _c("div", { staticClass: "tw-flex tw-w-full tw-mt-8 tw-flex-col" }, [
          _c("span", [_vm._v("latitude: " + _vm._s(_vm.latitude))]),
          _c("span", [_vm._v("longitude: " + _vm._s(_vm.longitude))])
        ])
      ],
      1
    ),
    _c("div", { staticClass: "tw-flex tw-flex-wrap tw-mt-16" }, [
      _c(
        "div",
        { staticClass: "tw-w-full" },
        [
          _vm.latitude && _vm.longitude
            ? _c("DataTable", {
                ref: "trillingstoolDatatable",
                attrs: {
                  options: _vm.tableOptions,
                  visibility: _vm.visibility,
                  limit: 25
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("p", [
        _vm._v(
          "*) Berekende maximale trillingssnelheid in mm/s (overschrijdingskans 50%)"
        )
      ]),
      _c("p", [
        _vm._v(
          "**) Berekende maximale trillingssnelheid in mm/s (overschrijdingskans 25%)"
        )
      ]),
      _c("p", [
        _vm._v(
          "***) Berekende maximale trillingssnelheid in mm/s (overschrijdingskans 1%)"
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }