import { render, staticRenderFns } from "./Earthquakes.vue?vue&type=template&id=3a12f379&"
import script from "./Earthquakes.ts?vue&type=script&lang=ts&"
export * from "./Earthquakes.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a12f379')) {
      api.createRecord('3a12f379', component.options)
    } else {
      api.reload('3a12f379', component.options)
    }
    module.hot.accept("./Earthquakes.vue?vue&type=template&id=3a12f379&", function () {
      api.rerender('3a12f379', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PeTAT/Tabs/Earthquakes/Earthquakes.vue"
export default component.exports