var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tw-flex tw-items-center tw-flex-wrap" },
      [
        _c("h3", { staticClass: "tw-w-full" }, [
          _vm._v("Weergave aardbevingen")
        ]),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Aardbevingen inladen",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleEarthquakes()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.earthquakes,
            callback: function($$v) {
              _vm.$set(_vm.includes, "earthquakes", $$v)
            },
            expression: "includes.earthquakes"
          }
        }),
        _c("v-checkbox", {
          staticClass: "tw-w-full",
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Effectgebied per aardbeving",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleEarthquakeRadius()
              _vm.visibilityChanged()
            }
          },
          model: {
            value: _vm.visible.earthquakeRadius,
            callback: function($$v) {
              _vm.$set(_vm.visible, "earthquakeRadius", $$v)
            },
            expression: "visible.earthquakeRadius"
          }
        }),
        _c("v-checkbox", {
          staticClass: "tw-w-full tw-mb-16",
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Pin bij epicentrum aardbeving",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleEarthquakeCenter()
              _vm.visibilityChanged()
            }
          },
          model: {
            value: _vm.visible.earthquakeCenter,
            callback: function($$v) {
              _vm.$set(_vm.visible, "earthquakeCenter", $$v)
            },
            expression: "visible.earthquakeCenter"
          }
        }),
        _c("DateFilter", {
          staticClass: "tw-w-1/2 tw-pr-8",
          attrs: { label: "Datum vanaf" },
          on: {
            handleChange: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.from,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "from", $$v)
            },
            expression: "internalFilters.from"
          }
        }),
        _c("DateFilter", {
          staticClass: "tw-w-1/2 tw-pl-8",
          attrs: { label: "Datum t/m" },
          on: {
            handleChange: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.until,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "until", $$v)
            },
            expression: "internalFilters.until"
          }
        }),
        _c("v-text-field", {
          staticClass: "tw-w-1/2 tw-pr-8",
          attrs: { "hide-details": "", label: "Minimale magnitude" },
          on: {
            change: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.magnitude_min,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "magnitude_min", $$v)
            },
            expression: "internalFilters.magnitude_min"
          }
        }),
        _c("v-text-field", {
          staticClass: "tw-w-1/2 tw-pl-8",
          attrs: { "hide-details": "", label: "Maximale magnitude" },
          on: {
            change: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.magnitude_max,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "magnitude_max", $$v)
            },
            expression: "internalFilters.magnitude_max"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tw-flex tw-items-center tw-flex-wrap tw-mt-32" },
      [
        _c("h3", { staticClass: "tw-w-full" }, [_vm._v("Trllingstool")]),
        _c("v-text-field", {
          staticClass: "tw-w-1/2 tw-pr-8",
          attrs: { "hide-details": "", label: "PGV grenswaarde" },
          on: {
            change: function($event) {
              return _vm.filtersChanged()
            }
          },
          model: {
            value: _vm.internalFilters.pgv,
            callback: function($$v) {
              _vm.$set(_vm.internalFilters, "pgv", $$v)
            },
            expression: "internalFilters.pgv"
          }
        }),
        _c(
          "div",
          { staticClass: "tw-w-1/2 tw-pl-8 self-end" },
          [
            _c("v-autocomplete", {
              staticClass: "with-full-border tw-p-4",
              attrs: {
                items: _vm.pgvModels,
                "item-text": "name",
                "item-value": "value",
                "hide-details": "",
                placeholder: "PGV Model"
              },
              on: {
                change: function($event) {
                  return _vm.filtersChanged()
                }
              },
              model: {
                value: _vm.internalFilters.pct,
                callback: function($$v) {
                  _vm.$set(_vm.internalFilters, "pct", $$v)
                },
                expression: "internalFilters.pct"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tw-flex tw-flex-col tw-flex-wrap tw-mt-32" },
      [
        _c("h3", { staticClass: "tw-w-full" }, [
          _vm._v("Velden en gebieden inladen:")
        ]),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Gasvelden",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleGasfields()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.gasfields,
            callback: function($$v) {
              _vm.$set(_vm.includes, "gasfields", $$v)
            },
            expression: "includes.gasfields"
          }
        }),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Gasvelden +6km",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleGasfields6Km()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.gasfields_6km,
            callback: function($$v) {
              _vm.$set(_vm.includes, "gasfields_6km", $$v)
            },
            expression: "includes.gasfields_6km"
          }
        }),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Zoutvelden",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleSaltfields()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.saltfields,
            callback: function($$v) {
              _vm.$set(_vm.includes, "saltfields", $$v)
            },
            expression: "includes.saltfields"
          }
        }),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Peilgebieden",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleBoezems()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.peilgebieden,
            callback: function($$v) {
              _vm.$set(_vm.includes, "peilgebieden", $$v)
            },
            expression: "includes.peilgebieden"
          }
        }),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Boezemgebieden",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleBoezems()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.boezems,
            callback: function($$v) {
              _vm.$set(_vm.includes, "boezems", $$v)
            },
            expression: "includes.boezems"
          }
        }),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Boezemgebieden 200m",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleBoezems200m()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.boezems200m,
            callback: function($$v) {
              _vm.$set(_vm.includes, "boezems200m", $$v)
            },
            expression: "includes.boezems200m"
          }
        }),
        _c("v-checkbox", {
          attrs: {
            "false-value": 0,
            "true-value": 1,
            label: "Controle/Test: nieuw gebied",
            color: "primary",
            "hide-details": ""
          },
          on: {
            change: function($event) {
              _vm.toggleTestgebieden()
              _vm.includesChanged()
            }
          },
          model: {
            value: _vm.includes.testgebieden,
            callback: function($$v) {
              _vm.$set(_vm.includes, "testgebieden", $$v)
            },
            expression: "includes.testgebieden"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }