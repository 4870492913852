import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { ReportPeTATFilters } from '@/components/PeTATFilters/PeTATFilters';
import { Report } from '@/models/Report';
import { formatDate } from '@/support/String';
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';

@Component<Trillingstool>({})
export default class Trillingstool extends Vue {
  // #region @Props
  @Prop()
  protected filters!: ReportPeTATFilters;

  @Prop({ default: null })
  protected latitude!: number | null;

  @Prop({ default: null })
  protected longitude!: number | null;
  // #endregion

  // #region @Refs
  @Ref()
  readonly trillingstoolDatatable!: DataTable;
  // #endregion

  // #region Class properties
  protected internalFilters: ReportPeTATFilters = {};
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.initialize();
  }

  protected initialize(): void {
    this.setInternalFilters();
  }
  // #endregion

  // #region Class methods
  protected filtersChanged(): void {
    this.$emit('filtersChanged', this.internalFilters);
  }

  protected setInternalFilters(): void {
    this.internalFilters = { ...this.internalFilters, ...this.filters };
  }
  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  protected get tableOptions(): TableOptions {
    return {
      model: new Report()
        .setTrillingsToolForCoordinates(this.latitude, this.longitude),
      headers: [
        {
          text: 'datum beving',
          value: 'occurred_at',
          transform: (occurred_at: string) => {
            if (! occurred_at) { return ''; }

            return formatDate(occurred_at, 'dd-LL-yyyy');
          },
        },
        {
          text: 'Tijd',
          value: 'occurred_at',
          transform: (occurred_at: string) => {
            if (! occurred_at) { return ''; }

            return formatDate(occurred_at, 'HH:mm:ss');
          },
        },
        {
          text: 'gasveld',
          value: 'gasfield_id',
          transform: (gasfield_id: number) => (gasfield_id === 1 ? 'G' : 'A'),
        },
        {
          text: 'epicentrum',
          value: 'city',
        },
        {
          text: 'afstand (km)',
          value: 'distance',
          transform: (distance: number) => {
            const kilometerCalculation = distance / 1000;
            return new Intl.NumberFormat('nl-NL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(kilometerCalculation);
          },
        },
        {
          text: 'magnitude',
          value: 'magnitude',
          class: 'text-right',
          transform: (magnitude: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }).format(magnitude),
        },
        {
          text: 'PGV 50% (mm/s)*',
          value: 'pgv_50pct',
          class: 'text-right',
          transform: (pgv_50pct: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(pgv_50pct),
        },
        {
          text: 'PGV 25% (mm/s)**',
          value: 'pgv_50pct',
          class: 'text-right',
          transform: (pgv_50pct: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(pgv_50pct * 1.4765),
        },
        {
          text: 'PGV 1% (mm/s)***',
          value: 'pgv_1pct',
          class: 'text-right',
          transform: (pgv_1pct: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(pgv_1pct),
        },
      ],
      actions: [],
    };
  }

  protected get visibility(): TableVisibility {
    return {
      checkboxes: false,
      title: false,
      search: false,
      limit: false,
    };
  }
  // #endregion

  // #region @Watchers
  @Watch('filters', { deep: true })
  protected internalfiltersChanged(): void {
    this.setInternalFilters();
  }
  // #endregion
}
