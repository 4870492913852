var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tw-flex tw-flex-wrap" }, [
        _c("h3", { staticClass: "tw-w-full tw-mb-8" }, [
          _vm._v("Coordinaten tools")
        ]),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-col tw-items-center tw-pr-16" },
          [
            _c(
              "span",
              {
                staticClass: "statusPill statusPill--bordered",
                class: _vm.isActiveSelectionTool
                  ? "statusBackgroundColor--green"
                  : "statusBorderColor--primary"
              },
              [_vm._v(" Selectie tool ")]
            ),
            _c("span", { staticClass: "tw-text-gray-500 tw-mt-8 tw-text-md" }, [
              _vm._v("SHIFT toets")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-col tw-items-center tw-px-16" },
          [
            _c(
              "span",
              {
                staticClass: "statusPill statusPill--bordered",
                class: _vm.isActiveCoordinatesTool
                  ? "statusBackgroundColor--green"
                  : "statusBorderColor--primary"
              },
              [_vm._v(" Coordinaten tool ")]
            ),
            _c("span", { staticClass: "tw-text-gray-500 tw-mt-8 tw-text-md" }, [
              _vm._v("ALT toets")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-col tw-items-center tw-pl-16" },
          [
            _c(
              "span",
              {
                staticClass: "statusPill statusPill--bordered",
                class: _vm.isActiveLockCoordinates
                  ? "statusBackgroundColor--green"
                  : "statusBorderColor--primary"
              },
              [_vm._v(" Coordinaten locken ")]
            ),
            _c("span", { staticClass: "tw-text-gray-500 tw-mt-8 tw-text-md" }, [
              _vm._v("L toets")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "tw-flex tw-w-full tw-mt-16 tw-mb-8 tw-flex-col" },
          [
            _c("span", [_vm._v("latitude: " + _vm._s(_vm.latitude))]),
            _c("span", [_vm._v("longitude: " + _vm._s(_vm.longitude))])
          ]
        )
      ]),
      _vm.$store.state.isBETA
        ? [
            _c(
              "div",
              {
                staticClass: "tw-flex tw-flex-wrap tw-justify-between tw-mt-32"
              },
              [
                _c("h3", [_vm._v("Coordinaten gebied")]),
                _c(
                  "div",
                  [
                    _c(
                      "MiButton",
                      {
                        staticClass: "tw-pr-8",
                        attrs: { color: "primary", small: true, compact: true },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.isCreatingMapArea = true
                          }
                        }
                      },
                      [_vm._v(" Nieuw gebied aanmaken ")]
                    ),
                    _c(
                      "MiButton",
                      {
                        staticClass: "tw-pl-8",
                        attrs: {
                          color: "secondary",
                          small: true,
                          compact: true
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.updateArea($event)
                          }
                        }
                      },
                      [_vm._v(" Aanpassingen opslaan ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "tw-flex tw-mt-16" }, [
              _c(
                "div",
                [
                  _c("MapAreaFilter", {
                    ref: "mapAreaFilter",
                    staticClass: "with-full-border tw-p-4",
                    attrs: {
                      area:
                        _vm.project && _vm.project.map_area
                          ? _vm.project.map_area
                          : null
                    },
                    on: { selectedArea: _vm.handleSelectedArea }
                  })
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      _c("div", { staticClass: "tw-w-full" }, [
        _vm.newAreaPoints && !_vm.newAreaPoints.length
          ? _c("p", { staticClass: "fadedText tw-mt-8" }, [
              _vm._v("Geen gebieden geselecteerd")
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-wrap item__list" },
          [
            _vm._l(_vm.newAreaPoints, function(position, index) {
              return [
                _c(
                  "div",
                  {
                    key: "area-" + index,
                    staticClass:
                      "tw-w-full tw-flex tw-items-center list__item cursor-pointer",
                    class:
                      _vm.activeMapAreaIndex === index
                        ? "list__item--active"
                        : "",
                    attrs: { wrap: "", "align-center": "" },
                    on: {
                      mouseenter: function($event) {
                        return _vm.setActiveMapAreaMaker(position)
                      },
                      click: function($event) {
                        return _vm.setActiveMapAreaIndex(index)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "tw-w-1/12" }, [
                      _vm._v(_vm._s(index + 1) + ".")
                    ]),
                    _c("div", { staticClass: "tw-w-4/12" }, [
                      _c("span", [_vm._v("latitude: " + _vm._s(position.lat))])
                    ]),
                    _c("div", { staticClass: "tw-w-4/12" }, [
                      _c("span", [_vm._v("longitude: " + _vm._s(position.lng))])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-w-3/12 text-xs-right item__icon item__icon--action delete"
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            on: {
                              click: function($event) {
                                return _vm.deleteArea(index)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            })
          ],
          2
        )
      ]),
      _vm.isCreatingMapArea
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "700px" },
              model: {
                value: _vm.isCreatingMapArea,
                callback: function($$v) {
                  _vm.isCreatingMapArea = $$v
                },
                expression: "isCreatingMapArea"
              }
            },
            [
              _c("CreateMapAreaDialog", {
                on: { mapAreaCreated: _vm.handleMapAreaCreated },
                model: {
                  value: _vm.isCreatingMapArea,
                  callback: function($$v) {
                    _vm.isCreatingMapArea = $$v
                  },
                  expression: "isCreatingMapArea"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }