var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tw-flex tw-flex-wrap tw-items-center" }, [
      _c("h3", { staticClass: "tw-w-full tw-mb-16" }, [
        _vm._v("Dossier filters:")
      ]),
      _vm.$store.state.isServiceOrganization
        ? _c(
            "div",
            { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
            [
              _c("ReportMediatorFilter", {
                on: { handleChange: _vm.filterChanged },
                model: {
                  value: _vm.filters.mediators,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "mediators", $$v)
                  },
                  expression: "filters.mediators"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.isServiceOrganization
        ? _c(
            "div",
            { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
            [
              _c("ReportOrganizationFilter", {
                on: { handleChange: _vm.filterChanged },
                model: {
                  value: _vm.filters.organizations,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "organizations", $$v)
                  },
                  expression: "filters.organizations"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.isServiceOrganization
        ? _c(
            "div",
            { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
            [
              _c("ReportHandlerFilter", {
                on: { handleChange: _vm.filterChanged },
                model: {
                  value: _vm.filters.report_handlers,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "report_handlers", $$v)
                  },
                  expression: "filters.report_handlers"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
        [
          _c("v-autocomplete", {
            staticClass: "with-full-border",
            attrs: {
              items: _vm.reportStatusMap,
              "item-text": "label",
              "item-value": "key",
              "hide-details": "",
              placeholder: "Status",
              clearable: "",
              multiple: ""
            },
            on: {
              change: function($event) {
                return _vm.filterChanged()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    index === 0
                      ? [
                          _c("span", [_vm._v("Status ")]),
                          _c("span", { staticClass: "grey--text caption" }, [
                            _vm._v(
                              "(+" + _vm._s(_vm.filters.statuses.length) + ")"
                            )
                          ])
                        ]
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.filters.statuses,
              callback: function($$v) {
                _vm.$set(_vm.filters, "statuses", $$v)
              },
              expression: "filters.statuses"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "tw-flex tw-flex-wrap tw-w-full" }, [
        _c(
          "div",
          { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("ReportTypeFilter", {
              on: { handleChange: _vm.filterChanged },
              model: {
                value: _vm.filters.types,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "types", $$v)
                },
                expression: "filters.types"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("ReportTagFilter", {
              on: { handleChange: _vm.filterChanged },
              model: {
                value: _vm.filters.tags,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "tags", $$v)
                },
                expression: "filters.tags"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("ReportSkillFilter", {
              on: { handleChange: _vm.filterChanged },
              model: {
                value: _vm.filters.skills,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "skills", $$v)
                },
                expression: "filters.skills"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/4 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("ReportDepartmentFilter", {
              on: { handleChange: _vm.filterChanged },
              model: {
                value: _vm.filters.departments,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "departments", $$v)
                },
                expression: "filters.departments"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/2 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("v-checkbox", {
              staticClass: "tw-p-0 tw-m-0",
              attrs: {
                label: "Verlopen deadline",
                color: _vm.getColor("secondary"),
                "hide-details": ""
              },
              on: {
                change: function($event) {
                  return _vm.filterChanged()
                }
              },
              model: {
                value: _vm.filters.overdue,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "overdue", $$v)
                },
                expression: "filters.overdue"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/2 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("v-checkbox", {
              staticClass: "tw-p-0 tw-m-0",
              attrs: {
                label: "PRIO dossiers",
                color: _vm.getColor("secondary"),
                "hide-details": ""
              },
              on: {
                change: function($event) {
                  return _vm.filterChanged()
                }
              },
              model: {
                value: _vm.filters.is_prio,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "is_prio", $$v)
                },
                expression: "filters.is_prio"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/2 tw-pb-16 tw-pr-24 custom-filter" },
          [
            _c("v-checkbox", {
              staticClass: "tw-p-0 tw-m-0",
              attrs: {
                label: "Verlopen contract KPI",
                color: _vm.getColor("secondary"),
                "hide-details": ""
              },
              on: {
                change: function($event) {
                  return _vm.filterChanged()
                }
              },
              model: {
                value: _vm.filters.overdue_kpi,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "overdue_kpi", $$v)
                },
                expression: "filters.overdue_kpi"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-1/2 tw-pb-16 custom-filter" },
          [
            _c("v-checkbox", {
              staticClass: "tw-p-0 tw-m-0",
              attrs: {
                label: "Special dossiers",
                color: _vm.getColor("secondary"),
                "hide-details": ""
              },
              on: {
                change: function($event) {
                  return _vm.filterChanged()
                }
              },
              model: {
                value: _vm.filters.is_special,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "is_special", $$v)
                },
                expression: "filters.is_special"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "tw-h-px tw-w-full tw-bg-gray-200 tw-my-32" }),
    _c(
      "div",
      { staticClass: "tw-flex tw-flex-wrap tw-items-stretch" },
      [
        _c("h3", { staticClass: "tw-w-full tw-mb-16" }, [
          _vm._v("Periode filters:")
        ]),
        _vm._l(_vm.periodFilters, function(periodFilter, index) {
          return _c(
            "div",
            {
              key: index + "-cell",
              staticClass: "tw-flex tw-flex-wrap tw-content-between tw-w-1/4",
              class: { "tw-pr-24": index + 1 !== _vm.periodFilters.length }
            },
            [
              _c(
                "div",
                { staticClass: "tw-w-full tw-pb-16" },
                [
                  _c("v-checkbox", {
                    staticClass: "tw-p-0 tw-m-0",
                    attrs: { label: periodFilter.title, "hide-details": "" },
                    on: {
                      change: function($event) {
                        return _vm.filterChanged()
                      }
                    },
                    model: {
                      value: periodFilter.enabled,
                      callback: function($$v) {
                        _vm.$set(periodFilter, "enabled", $$v)
                      },
                      expression: "periodFilter.enabled"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tw-w-full tw-pb-16" },
                [
                  _c("DateFilter", {
                    attrs: {
                      clearable: "",
                      label: periodFilter.title + " vanaf"
                    },
                    on: {
                      handleChange: function($event) {
                        return _vm.filterChanged()
                      }
                    },
                    model: {
                      value: periodFilter.from,
                      callback: function($$v) {
                        _vm.$set(periodFilter, "from", $$v)
                      },
                      expression: "periodFilter.from"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tw-w-full tw-pb-16" },
                [
                  _c("DateFilter", {
                    attrs: {
                      clearable: "",
                      label: periodFilter.title + " t/m"
                    },
                    on: {
                      handleChange: function($event) {
                        return _vm.filterChanged()
                      }
                    },
                    model: {
                      value: periodFilter.to,
                      callback: function($$v) {
                        _vm.$set(periodFilter, "to", $$v)
                      },
                      expression: "periodFilter.to"
                    }
                  })
                ],
                1
              )
            ]
          )
        })
      ],
      2
    ),
    _c("div", { staticClass: "tw-h-px tw-w-full tw-bg-gray-200 tw-my-32" }),
    _c("div", { staticClass: "tw-flex tw-flex-wrap" }, [
      _c("h3", { staticClass: "tw-w-full tw-mb-16" }, [
        _vm._v("Conclusie filters:")
      ]),
      _c(
        "div",
        { staticClass: "tw-w-full tw-mb-16" },
        [
          _c("v-checkbox", {
            staticClass: "tw-p-0 tw-m-0",
            attrs: { label: "Schadebedrag", "hide-details": "" },
            on: {
              change: function($event) {
                return _vm.filterChanged()
              }
            },
            model: {
              value: _vm.filters.filterPayoutTotal,
              callback: function($$v) {
                _vm.$set(_vm.filters, "filterPayoutTotal", $$v)
              },
              expression: "filters.filterPayoutTotal"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tw-w-1/4 tw-pr-12" },
        [
          _c("v-text-field", {
            attrs: { "hide-details": "", label: "Schadebedrag van" },
            on: {
              change: function($event) {
                return _vm.filterChanged()
              }
            },
            model: {
              value: _vm.filters.payout_total.from,
              callback: function($$v) {
                _vm.$set(_vm.filters.payout_total, "from", $$v)
              },
              expression: "filters.payout_total.from"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tw-w-1/4 tw-pl-12" },
        [
          _c("v-text-field", {
            attrs: { "hide-details": "", label: "Schadebedrag tot" },
            on: {
              change: function($event) {
                return _vm.filterChanged()
              }
            },
            model: {
              value: _vm.filters.payout_total.to,
              callback: function($$v) {
                _vm.$set(_vm.filters.payout_total, "to", $$v)
              },
              expression: "filters.payout_total.to"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }